@import 'variables.css';
@import 'fonts.css';
@import 'statusbar.css';


@tailwind base;

@tailwind components;

@tailwind utilities;

body {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-family: "Open Sans", sans-serif;
    color: #333;
}

a:hover, a:focus {
    color: #23527c;
    text-decoration: underline;
}
a {
    color: #337ab7;
    text-decoration: none;
}

.bg-gray {
    background-color: var(--bg-gray);
}
.bg-blue {
    background-color: var(--bg-blue);
}
.bg-red {
    background-color: var(--red-dark);
}
.bg-red-light {
    background-color: var(--red);
}

/* Headings / Text */
.text-red {
    color: var(--red);
}
.text-blue {
    color: var(--blue);
}
h1 {
    @apply text-red;
    font-size: 32px;
}
h2 {
    @apply text-blue;
    font-size: 28px;
}

/* Navigation */
header nav {
    height: var(--navbar-height);
    background-color: var(--blue);
}

header nav .logo {
    @apply absolute bg-white rounded-b-lg shadow z-50;
    top: 0;
    left: 0;
    width: 225px;
    margin-left: 25px;
    padding: 10px 25px 10px 25px;
    z-index: 999;
}

header nav .logo img {
    width: 175px;
}

header nav .menu {
    @apply flex;
}

header nav .menu li {
    line-height: 50px;
    border-left: 2px solid #456082;
    padding: 0 15px;
}
header nav .menu #products_submenu li {
    border-left: medium none;
    padding: 0 20px;
    line-height: 40px;
}
header nav .menu li:first-child {
    border-left: none;
    padding-left: 0;
}
header nav .menu li:last-child {
    padding-right: 0;
}
header nav .menu li a {
    @apply font-bold text-white;
}

/* Header */
.headerbox {
    @apply relative;
    height: 298px;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    border-left: 2px solid #000000;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    visibility: inherit;
    width: 100%;
}

.headerbox {
    background-image: url(/images/Allin_header.webp);
}

.headerbox .floating {
    @apply absolute h-full;
    background-color: rgba(0, 0, 0, 0.6);
    width: 280px;
    margin-left: 59%;
}
.headerbox label {
    @apply pb-4 font-semibold text-sm;
}
.headerbox input {
    @apply w-full leading-loose rounded text-black px-2;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.headerbox .btn {
    @apply w-full;
}
.btn {
    @apply rounded leading-loose;
    background-color: var(--red);
}

.abc {
    @apply text-white font-bold flex justify-center;
    font-size: 14px;
    background-color: var(--red);
}
.abc span {
    @apply w-10 text-center;
    line-height: 30px;
}
.abc a {
    color: inherit;
}

/* Home */
.jumbo {
    @apply bg-gray rounded mt-1;
    padding: 48px 60px;
}

.products {
    @apply grid grid-cols-4 gap-4;
}
.products img {
    @apply pt-3 pb-4;
}

.category-box {
    @apply grid grid-cols-4 gap-8 py-8;
}
.category-box h3 {
    @apply pt-4 pb-2;
}
.category-box img {
    @apply rounded-lg;
    height: 150px;
}
.category-box p {
    @apply text-gray-700 text-sm;
}
.category-box a:hover {
    @apply no-underline;
}
.category-box a p {
    @apply text-gray-700;
}
.category-box a p:hover {
    text-decoration: none;
}

/* Footer */

footer .footer {
    @apply text-white text-sm;
    background-color: var(--bg-blue);
    background-image: url("/images/active-in.webp");
    background-repeat: no-repeat;
    background-position: right;
    height: 192px;
}
footer .footer strong {
    @apply block font-semibold pb-4;
}
footer .footer a {
    @apply text-white;
}
footer .footer a:hover {
    @apply no-underline;
}
footer .footer li {
    line-height: 1.5rem;
}

/* Search */
.company-marker {
    @apply h-5 w-5 inline;
    color: var(--bg-gray)
}

/* Pagination */
.pagination {
    @apply flex py-6;
}

.pagination li.page-item {
    @apply flex h-8 w-8 justify-center items-center text-sm;
    border-top: 1px solid var(--bg-gray);
    border-bottom: 1px solid var(--bg-gray);
    border-left: 1px solid var(--bg-gray);
}

.pagination li.page-item:first-child {
    border-radius: 5px 0 0 5px;
}

.pagination li.page-item:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid var(--bg-gray);
}

.pagination .active {
    @apply text-white;
    background-color: var(--red);
}
.pagination a {
    color: var(--blue);
}

/* listing */
.company-listing {
    @apply py-6;
}
.company-details {
}

.businesshours {
    @apply text-sm;
}
.businesshours th {
    @apply text-left;
}
.businesshours td:first-child {
    @apply font-bold;
}
.businesshours td {
    @apply pr-2;
}

.company-keywords {
    @apply p-4 bg-blue rounded-lg text-white mt-4 text-sm leading-snug;
}

.company-categories {
    @apply p-4 bg-red rounded-lg text-white mt-4 text-sm leading-snug;
}

.company-contact {
    @apply p-4 bg-gray rounded-lg;
}

.company-content h1 {
    @apply text-3xl text-black pt-3 pb-2;
}
.company-content h2 {
    @apply text-2xl text-black pt-2 pb-1;
}
.company-content h3 {
    @apply text-xl text-black pt-2 pb-1;
}
.company-content h4,
.company-content h5,
.company-content h6 {
    @apply text-lg text-black pt-2 pb-1;
}

.company-content ol, .company-content ul {
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.company-content ol {
    list-style: decimal;
}
.company-content ul {
    list-style: disc;
}

/* vb */
.hideGoogleMaps {
    @apply hidden;
}
.search .maps {
    @apply bg-slate-300 w-full;
}
#map {
    @apply w-full border flex items-center justify-center p-8 text-xs;
}

.details .maps,
.maps-details .maps {
    @apply bg-slate-300 h-48 w-full;
}

.showOnMap {
    @apply cursor-pointer;
}

/* /vb */

.googlemaps {
    @apply flex items-center justify-around;
    height: 298px;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    border-left: 2px solid #000000;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.googlemaps {
    background-image: url(/images/Allin_header.webp);
}
.hideGoogleMaps {
    @apply hidden text-xs text-gray-500 text-right;
}
.hideGoogleMaps a {
    color: inherit;
}
.googlemaps-activate {
    @apply p-4 text-white rounded-lg shadow font-semibold;
    background-color: var(--bg-blue);
}
.googlemaps-activate a:hover {
    @apply underline
}
.googlemaps-activate a {
    @apply text-white;
}

/* Seopages */
.infopage-content h1,
.infopage-content h2,
.infopage-content h3,
.infopage-content h4,
.infopage-content h5,
.infopage-content h6
{
    @apply font-semibold;
    color: var(--red);
}

.infopage-content h2 {
    @apply text-xl pt-4 pb-3;
}
.infopage-content h3 {
    @apply text-xl pt-3 pb-2;
}
.infopage-companies {
    @apply grid grid-cols-2 gap-4;
}
.infopage-company {
    @apply bg-gray rounded-lg p-4 pt-2;
}
.infopage-company a {
    color: #022e65;
}
.infopage-desc {
    @apply block leading-snug text-sm;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

/* Forms */
.form-block {
    @apply mb-4;
}
.form-block label {
    @apply block font-bold;
}
.form-block input,
.form-block textarea
{
    @apply block w-full;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-block textarea {
    height: auto;
}
.form-block .has-errors {
    @apply text-sm;
    color: var(--red);
}

.button {
    @apply flex py-2 px-3 rounded-lg bg-red font-semibold text-white text-sm;
    border: 2px solid var(--red-dark)
}
.button:hover {
    @apply bg-white;
    color: var(--red-dark);
    border: 2px solid var(--red-dark)
}
.button svg {
    @apply h-5 w-5 mr-2;
}

.flashmessage {
    @apply text-white my-4 rounded py-2 px-4 font-semibold;
    background-color: var(--red-dark);
}

/* Pages */
.products-marker {
    @apply text-white h-16 w-16 my-2;
}

.svg-list svg {
    @apply inline-block h-5 w-5 mr-2;
    color: var(--bg-gray);
}

.mobile-menu { display: none; }

label[for="verklaring"] a {
    color: var(--bg-blue);
}

/* Cities */
.cities-divider-mechelen {
    @apply col-span-2 bg-cover bg-center border-2 border-black;
    background-image: url('/images/steden-mechelen.webp');
    height: 289px;
}

.citymaps {
    background-color: var(--bg-gray);
}
.citymaps .googlemaps-activate {
    margin: 1rem;
}

.citymaps .googlemaps, .citymaps.googlemaps {
    @apply rounded-lg;
    border: medium none;
}
.citymaps .relative {
    @apply w-full;
}
.citymaps.googlemaps,
.citymaps .googlemaps {
    background-image: none;
}

.citymaps .googlemaps {
    align-items: baseline;
}

.companies-in-city img {
    max-height: 130px;
}

#categories a {
    line-height: 2rem;
}

.category-desc h1 {
    @apply text-2xl font-bold py-4;
}
.category-desc p {
    @apply py-2;
}

@media (max-width: 768px) {
    .googlemaps-activate {
        margin: 1.5rem;
    }
    header nav {
        height: auto;
    }
    header nav > div, header nav ul.menu {
        flex-direction: column;
    }
    header nav .menu {
        width: 100%;
        display: none;
    }
    header nav .menu li {
        padding: 0;
        border: medium none;
        border-bottom: 2px solid #456082;
        text-align: center;
        width: 100%;
    }
    header nav .logo {
        position: static;
        margin: 0;
        padding: 0;
        width: 100%;
        border-radius: 0;
        display: flex;
        justify-content: space-between;
    }
    .mobile-menu {
        @apply px-6;
        display: flex;
        align-items: center;
        align-content: center;
    }
    .mobile-menu svg {
        cursor: pointer;
    }
    .jumbo {
        padding: 24px 30px;
    }
    .products, .category-box {
        grid-template-columns: 1fr;
    }
    .category-box img {
        width: 100%;
        height: auto;
    }
    .category-box > div {
        padding: 0 30px;
    }
    footer .footer {
        background-image: none;
    }
    footer .footer a {
        line-height: 2rem;
    }
    .headerbox .floating {
        position: static;
        margin-left: 0;
        width: 100%;
    }
    footer .footer .grid {
        grid-template-columns: 1fr;
        background-color: var(--bg-blue);
    }
    #producten, #voordelen, #aanmelden, #contact, #cookies, #searchresults, .company-listing {
        @apply px-6;
    }
    #producten .flex, #voordelen .flex {
        flex-direction: column;
    }
    #producten .grid-cols-2 {
        grid-template-columns: 1fr;
    }
    #voordelen .jumbo {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    #searchresults.grid-cols-2 {
        grid-template-columns: 1fr;
    }
    #searchresults .float-right {
        @apply float-none mb-2;
    }
    #categories {
        grid-template-columns: 1fr;
    }

    .abc {
        width: auto;
        overflow-x: auto;
        overflow-y: hidden;
        letter-spacing: 2rem;
    }

    .headerbox {
        background-image: url(/images/Allin_header_768.webp);
    }

    .pagination-wrapper {
        @apply px-6;
    }
}
@media (max-width: 360px) {
    .headerbox {
        background-image: url(/images/Allin_header_360.webp);
    }
}

.keyword-list li {
    @apply pr-2;
}
.keyword-list li:after {
    content: ',';
}
.keyword-list li:last-child:after {
    content: '';
}
